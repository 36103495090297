<template>
  <div class="form-section">
    <div class="title-2">Clients With T5</div>
    <el-row>
      <el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 20px">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="13"
            :label_span="11"
            :options="TaxFilingTypeDropdown"
            :translateLabel='false'
            :translateLabels="false"
            @amUpdated="(p, val) =>  { taxReportingType = val}"
            field_mark="required"
            label="Filing Type"
            name="tax_filing_type_id"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" style="width: 220px;margin-left: 10px">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="12"
            :label_span="12"
            :options="dropdownTaxReportingPeriods"
            :translateLabel='false'
            :translateLabels="false"
            @amUpdated="(p, val) =>  { taxReportingPeriod = val}"
            field_mark="required"
            label="Reporting Type"
            name="tax_reporting_period_type_id"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" style="width: 160px;margin-left: 10px;">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="10"
            :label_span="13"
            :options="dropdown12Month"
            :translateLabel='false'
            :translateLabels="false"
            field_mark="required"
            label="Period Due"
            name="tax_taxes_to_be_done_report_period_end_value"
            placeholder=""
            rules=""></dg-select-field>
      </el-col>

      <el-button style="position: absolute;right: 10px;" type="primary" size="small">Report</el-button>
    </el-row>
  </div>
</template>

<script>
import TaxFilingTypeJson from "@/json/dropdown/TaxFilingType.json";
import jsonTaxReportingPeriods from "@/json/dropdown/TaxReportingPeriods.json";
import json12month from "@/json/dropdown/12Month.json";
export default {
  props: {
    control: {
      required: true
    }
  },
  data () {
    return {
      TaxFilingTypeDropdown: TaxFilingTypeJson,
      dropdownTaxReportingPeriods: jsonTaxReportingPeriods,
      dropdown12Month: json12month,
      taxReportingType: null
    }
  }
}
</script>
